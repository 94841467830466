import FZPlayerEvents from './FZPlayerEvents';

export default class FZPlayerInterface
{

	// DOM id of player iFrame
	// will be set in LoadPlayer
	playerFrameID = "";


	// cached player variable for messages
	playerWindow = null;

	// object to receive messages from player
	Callback = null;


	// messages we send to player

	PLAY_ZING = "playZing";
	// start playback of a new zing
	// data is JSON object
	// current merge data is cleared

	/*
		"data": {
					"zingId": zing_id, // shareable id of zing, eg "dfRTHGSW"
					"mergeData": // the available merge data, as JSON object
					{
						"name": "Freddy",
						"order": "Super Supreme"
					}
					"settings" : {
									// none defined yet
								 }
				}
	*/

	PAUSE_PLAYBACK = "pausePlayback";
	// pause zing playback
	// data = undefined

	RESUME_PLAYBACK = "resumePlayback";
	// resume zing playback after pausing
	// data = undefined;


	REPLAY_ZING = "replayZing";
	// replay zing from start
	// data = undefined


	PREPARE_TO_LOAD_NEW_ZING = "prepareToLoadNewZing";
	// fades out current zing and stops playback, prepares to load new one
	// this.PLAY_ZING calls this before playing, there is no need to call it if calling this.PLAY_ZING
	// data = undefined

	REQUEST_PLAYER_STATE = "requestPlayerState";
	// asks player to send this.PLAYER_PAUSED message with current status
	// needed because of cross-domain issues, can not get player state directly
	// data = undefined


	UNLOAD_ZING = "unloadZing";
	// unload loaded zing

	CLEAR_MERGE_DATA = "clearMergeData";
	// clears all the current merge data

	REQUEST_MERGE_TAGS = "requestMergeTags";
	// ask player to send.MERGE_TAGS with current merge tags

	REQUEST_MERGE_DATA = "requestMergeData";
	// ask player to send.MERGE_DATA with current merge data

	UPDATE_MERGE_TAGS = "updateMergeTags";
	// update merge tags
	// data = array of type/tag/value objects
	//    [ { "type": "text", tag": "name", "value": "Sally"}, { "type": "image", tag": "profile": "https://example.com/pic1.jpg"} ]
	// tags passed in are combined with current merge data

	REMOVE_MERGE_TAGS = "removeMergeTags";
	// remove individual merge tag values
	// data = array of tag names
	//   [ "name", "order" ]

	GOTO_PAGE = "gotoPage";

	INIT_YOUTUBE = "initYouTube";
	// call to set up youtube player
	// use if you might later be using YouTube in merge tags, to give time to load

	ZING_THUMB = "zingThumb";
	// call to set the image behind the play button when not autoplaying
	// set before calling LoadZing
	// data is string - url of image

	// we received a message from the player
	// the event is created in app object, could be created directly here if no other messages are expected
	MessageReceived = function( msg )
	{

		if ( ! msg || ! msg.message )
		{
			return;
		}

		switch( msg.message )
		{
			case FZPlayerEvents.PLAYER_READY:

				if ( this.Callback && typeof this.Callback.FZ_PlayerLoaded === "function" )
				{
					this.Callback.FZ_PlayerLoaded();
				}
				// if you have no other work to do before playback, send the PLAY_ZING message now to start playback
				break;

			case FZPlayerEvents.HTML_PLAYER_READY:

				if ( this.Callback && typeof this.Callback.FZ_PrepareForPlayback === "function" )
				{
					this.Callback.FZ_PrepareForPlayback();
				}
				break;

			case FZPlayerEvents.PLAYER_PAUSED:

				if ( msg.data )
				{
					if ( this.Callback && typeof this.Callback.FZ_PlayerPaused === "function" )
					{
						this.Callback.FZ_PlayerPaused(msg.data.paused);
					}
				}
				break;

			case FZPlayerEvents.ZING_FINISHED:

				if ( this.Callback && typeof this.Callback.FZ_PlaybackFinished === "function" )
				{
					this.Callback.FZ_PlaybackFinished();
				}
				break;

			case FZPlayerEvents.SHOW_PAGE:

				if ( this.Callback && typeof this.Callback.FZ_ShowPage === "function" )
				{
					this.Callback.FZ_ShowPage(msg.data.page, msg.data.numPages);
				}
				break;

			case FZPlayerEvents.MERGE_TAGS:

				if ( this.Callback && typeof this.Callback.FZ_MergeTags === "function" )
				{
					this.Callback.FZ_MergeTags(msg.data);
				}
				break;

			case FZPlayerEvents.MERGE_DATA:

				if ( this.Callback && typeof this.Callback.FZ_MergeData === "function" )
				{
					this.Callback.FZ_MergeData(msg.data);
				}
				break;

			default:

				break;

		}
	}


	constructor( options )
	{
		if ( options )
		{
			this.Callback = options.Callback;
		}
		window.addEventListener("message", this.handleMessage );
	}

	handleMessage = ( event ) => {

		if ( this.PlayerOrigin() === event.origin )
		{
			// console.log( event.data );
			this.MessageReceived(event.data );
		}
	};

	// start playback of new zing, pass in string id of zing, eg 'qymFDvRk' and mergeData as object
	// to load and start playback, send PLAY_ZING message with appropriate data
	PlayZing = function( zing_id, mergeData )
	{
		this.postMessage( { "message": this.PLAY_ZING,
								"data": {
									"zingId": zing_id,
									"mergeData": mergeData || {},
									"settings" : {}
								}
							} );
	}

	UpdateMergeTags = function( tags )
	{

		this.postMessage( { "message": this.UPDATE_MERGE_TAGS,
								"data": tags
							} );
	}

	InitYouTube = function()
	{
		this.postMessage( { "message": this.INIT_YOUTUBE,
								"data": {}
						  } );
	}

	ClearMergeData = function( tags )
	{

		this.postMessage( { "message": this.CLEAR_MERGE_DATA,
								"data": {}
						  } );
	}

	// load player, pass in id of embed frame as string
	// only needs to be done once, if loading another zing later, just call PlayZing
	// when player is loaded, a PLAYER_READY message will be sent
	LoadPlayer = function( embedFrame )
	{
		this.playerFrameID = embedFrame;

		// $('#' + embedFrame).attr( 'src', this.PlayerUrl() );
		document.getElementById( embedFrame ).src = this.PlayerUrl();
	}

	PausePlayback = function()
	{
		this.postMessage( { "message": this.PAUSE_PLAYBACK,
								"data": {}
							} );
	}

	ResumePlayback = function()
	{
		this.postMessage( { "message": this.RESUME_PLAYBACK,
								"data": {}
							});
	}

	ReplayZing = function()
	{
		this.postMessage( { "message": this.REPLAY_ZING,
								"data": {}
							} );
	}

	PrepareToLoadNewZing = function()
	{

		this.postMessage( { "message": this.PREPARE_TO_LOAD_NEW_ZING,
								"data": {}
							} );
	}

	RequestPlayerState = function()
	{

		this.postMessage( { "message": this.REQUEST_PLAYER_STATE,
								"data": {}
							} );
	}

	RequestMergeTags = function()
	{
		this.postMessage( { "message": this.REQUEST_MERGE_TAGS,
								"data": {}
							} );
	}

	RequestMergeData = function()
	{
		this.postMessage( { "message": this.REQUEST_MERGE_DATA,
								"data": {}
							} );
	}

	GotoPage = function( page )
	{
		this.postMessage( { "message": this.GOTO_PAGE,
								"data": { page: page }
							} );
	}

	SetZingThumb = function( url )
	{
		this.postMessage( { "message": this.ZING_THUMB,
								"data": url
							} );
	}

	postMessage = function( msg )
	{
		var player = this.PlayerWindow();
		if ( ! player )
		{
			return;
		}

		player.postMessage( msg, this.PlayerOrigin() );
	}


	// helper functions to get iFrame window
	PlayerWindow = function()
	{
		if ( ! this.playerWindow )
		{
			this.playerWindow = this.getIframeWindow(document.getElementById(this.playerFrameID));
		}

		if ( ! this.playerWindow )
		{
			return null;
		}

		return this.playerWindow;
	}

	getIframeWindow = function(iframe_object)
	{
	  var doc;

	  if (iframe_object.contentWindow) {
	    return iframe_object.contentWindow;
	  }

	  if (iframe_object.window) {
	    return iframe_object.window;
	  }

	  if (!doc && iframe_object.contentDocument) {
	    doc = iframe_object.contentDocument;
	  }

	  if (!doc && iframe_object.document) {
	    doc = iframe_object.document;
	  }

	  if (doc && doc.defaultView) {
	   return doc.defaultView;
	  }

	  if (doc && doc.parentWindow) {
	    return doc.parentWindow;
	  }

	  return undefined;
	}

	// passed to player to enable cross-domain messaging
	PlayerOrigin = function()
	{
		//return "https://quickzing.com";
		return "https://fabzing.com";
	//	return "http://local.fabtale.com";

	}

	PlayerBase = function()
	{
	//	return this.PlayerOrigin() + "/player/dev/pvmPlayer.html";
		// return this.PlayerOrigin() +  "/player/258c/pvmPlayer.html";
		return this.PlayerOrigin() +  "/player/272w/pvmPlayer.html";

	}


	PlayerUrl = function()
	{
		var base = this.PlayerBase();

		if (!window.location.origin)
		{
  			window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
		}
		// include origin in url so PostMessage can pass messages
		return base + "?origin=" + window.location.origin ;
	}


}

/*
var playerInterface = new FZPlayerInterface();
playerInterface.playerFrameID = "zingPlayer";
playerInterface.Callback = this;

// store.subscribe(this.storeChanged);
window.addEventListener("message", function(event) {

	if ( playerInterface )
	{
		if ( playerInterface.PlayerOrigin() === event.origin )
		{
			playerInterface.MessageReceived(event.data );
		}
	}
});
*/
/*
function FZ_PlayerPaused(paused)
{
	if ( paused )
	{
		$('#pauseButton').hide();
		$('#playButton').show();
	}
	else
	{
		$('#pauseButton').show();
		$('#playButton').hide();
	}
}

function FZ_ShowPage( page, numPages)
{
	if ( numPages <= 1 )
	{
		$('#prevPageButton').hide();
		$('#nextPageButton').hide();
	}
	else
	{
		if ( page < numPages )
		{
			$('#nextPageButton').show();
		}
		else
		{
			$('#nextPageButton').hide();
		}
		if ( page > 1 )
		{
			$('#prevPageButton').show();
		}
		else
		{
			$('#prevPageButton').hide();
		}
	}
	$('#toolbar').css( { "display": "block", opacity: 0.7} );
}

$('#pauseButton').on( "click", function() {
	if ( playerInterface )
	{
		playerInterface.PausePlayback();
	}
})

$('#playButton').on( "click", function() {
	if ( playerInterface )
	{
		playerInterface.ResumePlayback();
	}
})

$('#replayButton').on( "click", function() {
	if ( playerInterface )
	{
		playerInterface.ReplayZing();
	}
})

$('#nextPageButton').on( "click", function() {
	if ( playerInterface )
	{
		playerInterface.GotoPage("next");
	}
})

$('#prevPageButton').on( "click", function() {
	if ( playerInterface )
	{
		playerInterface.GotoPage("back");
	}
})
*/