import React from 'react';
import FZPlayerInterface from './FZPlayerInterface';
import FZPlayerEvents from './FZPlayerEvents';
import "./QuickZingPlayer.css"
const queryString = require('query-string');

export default class QuickZingPlayer extends React.Component
{
    playerInterface = null;


    // racing on the go
    // zing_to_play = "RfzDxqHp";
 //    zing_to_play = "RfzDxqHg"
 //    play_thumb_url = "https://fst.fabtale.com/qz/0/racing2.jpg";

    // tvsn
   // zing_to_play = "yFQWdyjT";
   // play_thumb_url = "https://fst.fabtale.com/qz/0/tvsn-splash.jpg";
/*
    // ppwa
    zing_to_play = "LNwSQgRf"
    play_thumb_url = "https://fst.fabtale.com/qz/0/ppwa-splash.jpg";

    // chemist warehouse
    zing_to_play = "VTcTQKtt";
    play_thumb_url = "https://fst.fabtale.com/qz/0/chemist-warehouse-logo-600.jpg";

    // tan on the go
    // Royal Botanic Gardens
    zing_to_play = "pKJXPqsW";
    play_thumb_url = "https://fst.s3.amazonaws.com/fst/000/420/1575950289768_oVGkNxb5.jpg";
*/
    // renaultontherun
 //   zing_to_play = "MWHjCNsS";
 //   play_thumb_url = "https://fst.s3.amazonaws.com/qz/0/renault_splash.jpg";


    // 360 bizcard
    zing_to_play =  "HQcvdHtM";
    play_thumb_url = "https://fst.fabtale.com/qz/0/360_splash3.jpg";

    // multiworks
    zing_to_play = "yQSZvwjb";
    play_thumb_url = "https://fst.fabtale.com/qz/0/multiworks-splash.jpg";

    constructor( props )
    {
        super( props );

        let parsed = queryString.parse(window.location.search);
        if ( parsed.s && parsed.s.length > 0 )
        {
            this.zing_to_play = parsed.s;
        }

        this.playerInterface = new FZPlayerInterface( { Callback: this, PlayThumb: this.play_thumb_url } );

        this.state = { playerPaused: true, showPlayerButtons: false, playerLoaded: false, showCover: true };
    }

    componentDidMount() {
        this.playerInterface.LoadPlayer("quickZingPlayer");
    }

    FZ_PlayerLoaded = ( ) => {

        setTimeout( () => {
            // fades out cover
            this.setState( { playerLoaded: true } );
        }, 100 );

        setTimeout( () => {
            // make sure cover is gone
            this.setState( { showCover: false } );
        }, 400 );

        this.playerInterface.SetZingThumb( this.play_thumb_url );
        this.playerInterface.PlayZing( this.zing_to_play, {} );
        if ( this.props.onPlayerEvent )
        {
            this.props.onPlayerEvent( FZPlayerEvents.PLAYER_READY,  {} );
        }
    }

    FZ_ShowPage = ( page, numPages ) =>
	{
        // a new page is about to be shown
        this.setState( { showPlayerButtons: true } );
        if ( this.props.onPlayerEvent )
        {
            this.props.onPlayerEvent( FZPlayerEvents.SHOW_PAGE, { page: page, numPages: numPages} );
        }
    }

    FZ_PlayerPaused = ( paused ) =>
    {
        this.setState( { playerPaused: paused } );
    }

    pauseClicked = () => {
       if ( this.playerInterface )
       {
           this.playerInterface.PausePlayback();
       }
    }

    resumeClicked = () => {
        if ( this.playerInterface )
        {
            this.playerInterface.ResumePlayback();
        }
     }

     replayClicked = () => {
        if ( this.playerInterface )
        {
            this.playerInterface.ReplayZing();
        }
     }

    render() {

        let coverClass = "qzp_quickZingPlayerLoadingCover";
        if ( this.state.playerLoaded )
        {
            coverClass += " animated fadeout";
        }


        return (
            <div className="qzp_quickZingPlayerHolder">

                <iframe id="quickZingPlayer" allow="autoplay" title="Video Receipt Player" className="qzp_quickZingPlayer" scrolling="no"></iframe>
                {this.state.showPlayerButtons && <div className="qzp_buttons">
                    {this.state.playerPaused && <div onClick={this.resumeClicked} className="qzp_play_button"></div>}
                    {! this.state.playerPaused && <div onClick={this.pauseClicked} className="qzp_pause_button"></div>}
                    <div onClick={this.replayClicked} className="qzp_replay_button"></div>
                </div>}
                {this.state.showCover &&
                    <div className={coverClass} ><div>Loading...</div></div>
                }
            </div>
        )
    }

}